import React from "react";
import { GetStaticPropsContext } from "next";
import { getStaticPageContent, Page } from "../components";
import serverSideTranslations from "../utils/serverSideTranslations";
import {
  Billboard,
  BlogCard,
  Button,
  CallToAction,
  CardLayout,
  HomeHero,
  HtmlRenderBlock,
  imageLoader,
  ImagePullout,
  InFocus,
  LocationCard,
  QuietLinkProps,
  QuietLinks,
  ReviewCardProps,
  ReviewsList,
  SubscribeWrapper,
  TripIdeaCard,
  WPImage,
} from "@travellocal/ui";
import { cms } from "../utils/api";
import Image from "next/image";
import { useRouter } from "next/router";
import { getLandingPageByPath, getTripIdeaCardDataByUrl } from "../utils/api/site2";
import { getArticleTags } from "../utils/api/opengraph";
import { SUPPORTED_LOCALES, isLocale, site1Api } from "@travellocal/utils";
import { translateRoute } from "../utils/localisedRouteHelpers";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import styles from "./index.module.scss";
import { getSubscribe } from "utils/api/cms";

export const getStaticProps = async (context: GetStaticPropsContext) => {
  if (!isLocale(context.locale)) {
    return {
      notFound: true,
    };
  }

  const pageProps = await getStaticPageContent(context);
  pageProps.alternatePages = SUPPORTED_LOCALES.map((locale) => ({
    locale,
    url: translateRoute(locale, "index"),
  }));

  const pageLocale = pageProps.initialAppState.locale;

  const pageContent = await cms.getHomePage(pageLocale);
  const data = pageContent.fields.components;

  const inFocusArticles = await Promise.all(
    data.infocusArticles?.map(async ({ url }) => getArticleTags(url)) ?? []
  );

  const featuredArticles = await Promise.all(
    data.articlesArticles?.map(async ({ url }) => getArticleTags(url)) ?? []
  );

  const featuredTripIdeas1 = await Promise.all(
    data.featuredtripideas1Tripideas?.map(async ({ url }) =>
      getTripIdeaCardDataByUrl(pageLocale, url)
    ) ?? []
  );

  const featuredTripIdeas2 = await Promise.all(
    data.featuredtripideas2Tripideas?.map(async ({ url }) =>
      getTripIdeaCardDataByUrl(pageLocale, url)
    ) ?? []
  );

  const subscribeResponse = await getSubscribe([pageLocale]);

  const locationCards = await Promise.all(
    data.locationcardsLocations?.map(async ({ color, url }) => {
      const { pathname } = new URL(url);
      const lpdata = await getLandingPageByPath(pageLocale, pathname);

      if (!lpdata) {
        return null;
      }

      const imageSrc = lpdata.headerPhoto
        ? `${process.env.ASSETS_DOMAIN}/originals/${lpdata.headerPhoto.filename}`
        : null;

      return {
        id: lpdata.id,
        title: lpdata.mainKeyword,
        href: pathname,
        color,
        imageSrc,
        imageAlt: lpdata.headerPhotoImgAltText,
      };
    }) ?? []
  );

  return {
    revalidate: 1800,
    props: {
      ...(await serverSideTranslations(pageLocale)),
      pageProps,
      pageContent: pageContent ?? {},
      inFocusArticles: inFocusArticles.filter(Boolean),
      featuredArticles: featuredArticles.filter(Boolean),
      locationCards: locationCards.filter(Boolean),
      featuredTripIdeas1: featuredTripIdeas1.filter(Boolean),
      featuredTripIdeas2: featuredTripIdeas2.filter(Boolean),
      subscribeResponse,
    },
  };
};

type Props = Omit<PropsOf<typeof getStaticProps>, "_nextI18Next">;

function PageWrapper({
  pageProps,
  pageContent,
  inFocusArticles,
  featuredArticles,
  locationCards,
  featuredTripIdeas1,
  featuredTripIdeas2,
  subscribeResponse,
}: Props) {
  const data = pageContent.fields.components;

  const router = useRouter();
  const { t } = useTranslation();
  const { subscribeBodyText, subscribeText } =
    subscribeResponse[0].globalContent.subscribeComponent;
  const canonicalUrl = translateRoute(pageProps.initialAppState.locale, `index`);

  return (
    <Page
      title={pageContent.meta.pageTitle}
      meta={{
        title: pageContent.meta?.searchTitle,
        description: pageContent.meta?.searchDescription,
        imageSrc: pageContent.meta?.searchImage?.sourceUrl,
        locale: pageProps.initialAppState.locale,
        canonicalUrl,
      }}
      {...pageProps}>
      <HomeHero
        title={data.homeheroTitle}
        subtitle={data.homeheroSubtitle}
        cta={data.homeheroCta as CallToAction}
        destinations={pageProps.countries?.map((x) => ({
          label: x.name,
          value: x.href,
          enabled: true,
        }))}
        video={
          data.videomodalEmbed
            ? {
              ctaLabel: data.videomodalCtalabel,
              modalAriaLabel: data.videomodalArialabel,
              embedHtml: data.videomodalEmbed,
            }
            : undefined
        }
        onDestinationSelect={(val) => {
          if (val && val.enabled !== false) {
            router.push(val.value);
          }
        }}
      />

      <div className="has-text-centered">
        {data.quietlinksLinks && (
          <QuietLinks quietLinks={data.quietlinksLinks as QuietLinkProps[]} />
        )}
      </div>

      {data.billboard1BillboardContent && (
        <div className="container my-8">
          <div className="columns is-centered">
            <div className="column is-12 is-10-desktop">
              <Billboard
                backgroundImage={data.billboard1BillboardImage as WPImage}
                content={data.billboard1BillboardContent}
                callToAction={data.billboard1BillboardCta as CallToAction}
                heading={data.billboard1BillboardCaption}
                style="light"
                imageSide="left"
              />
            </div>
          </div>
        </div>
      )}

      {inFocusArticles?.length == 3 && (
        <div className="container p-0-touch my-8">
          <InFocus
            title={data.infocusTitle}
            subtitle={data.infocusSubtitle}
            description={data.infocusDescription}
            articles={inFocusArticles}
            video={
              data.videomodalEmbed
                ? {
                  ctaLabel: data.videomodalCtalabel,
                  modalAriaLabel: data.videomodalArialabel,
                  embedHtml: data.videomodalEmbed,
                }
                : undefined
            }
          />
        </div>
      )}

      <SubscribeWrapper
        subscribeClassName={classnames("container mt-8 mb-8 is-12-mobile is-10", styles.subscribe)}
        subscribeColumnClassName={classnames(styles.subscribeColumn)}
        title={subscribeText}
        bodyText={subscribeBodyText}
        locale={pageProps.initialAppState.locale}
        signupSource={"home_page"}
        dataTrackingIds={{
          firstName: "frm.subscribe.first_name",
          email: "frm.subscribe.email",
        }}
      />

      {locationCards?.length > 0 && (
        <div className="container my-8 overflow-iphone-fix">
          <CardLayout
            layout="auto"
            title={data.locationcardsTitle}
            cta={data.locationcardsCta as CallToAction}>
            {locationCards.map((location) => (
              <LocationCard
                key={location.id}
                title={location.title}
                color={location.color}
                image={{
                  sourceUrl: location.imageSrc,
                  altText: location.imageAlt,
                }}
                href={location.href}
              />
            ))}
          </CardLayout>
        </div>
      )}

      {data.billboard2BillboardContent && (
        // TODO refactor this into a UI component
        <div className="container my-5">
          <div className="columns is-vcentered">
            <div className="column is-4 is-offset-2">
              <HtmlRenderBlock htmlString={data.billboard2BillboardContent} />
              {data.billboard2BillboardCta && (
                <div className="is-pulled-left">
                  <Button
                    href={data.billboard2BillboardCta.url}
                    isGhost
                    target={data.billboard2BillboardCta.target}>
                    {data.billboard2BillboardCta.title}
                  </Button>
                </div>
              )}
            </div>
            <div className="column is-5">
              {data.billboard2BillboardImage && (
                <Image
                  src={data.billboard2BillboardImage.sourceUrl}
                  alt={data.billboard2BillboardImage.altText}
                  layout="intrinsic"
                  width={data.billboard2BillboardImage.mediaDetails.width}
                  height={data.billboard2BillboardImage.mediaDetails.height}
                  loader={imageLoader}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {data.reviewslistReviews?.length > 0 && (
        <div className="container my-8">
          <ReviewsList reviews={data.reviewslistReviews as ReviewCardProps["review"][]} />
        </div>
      )}

      {featuredArticles?.length > 0 && (
        <div className="container my-8">
          <div className="columns">
            {featuredArticles.map((article) => {
              // Workaround for hydration error when rendering dates.
              // JS Date() creates an object in local time, which will vary between server and client
              // We only care about the date, so recreate a local date at midnight to avoid TZ shift
              // https://github.com/date-fns/date-fns/issues/489#issuecomment-357732898
              const [year, month, day] = article.date.substr(0, 10).split("-");
              const date = new Date(year, month - 1, day);

              return (
                <div className="column" key={article.url}>
                  <BlogCard
                    size="hero"
                    title={article.title}
                    url={article.url}
                    target="_self"
                    image={article.imageSrc}
                    date={date}
                  />
                </div>
              );
            })}
          </div>

          {data.articlesCta && (
            <div className="level my-6">
              <div className="level-item">
                <Button
                  type="link"
                  href={data.articlesCta.url}
                  target={data.articlesCta.target}
                  isGhost>
                  {data.articlesCta.title}
                </Button>
              </div>
            </div>
          )}
        </div>
      )}

      {data.billboard3BillboardContent && (
        <div className="container my-8">
          <Billboard
            backgroundImage={data.billboard3BillboardImage as WPImage}
            content={data.billboard3BillboardContent}
            callToAction={data.billboard3BillboardCta as CallToAction}
            heading={data.billboard3BillboardCaption}
          />
        </div>
      )}

      {data.featuredlocation1ImagepulloutCta && (
        <div className="container my-8">
          <ImagePullout
            heading={data.featuredlocation1ImagepulloutTitle}
            text={data.featuredlocation1ImagepulloutDescription}
            callToAction={data.featuredlocation1ImagepulloutCta as CallToAction}
            bgSrc={data.featuredlocation1ImagepulloutImage.sourceUrl}
          />
        </div>
      )}

      {featuredTripIdeas1?.length > 0 && (
        <div className="container my-8 overflow-iphone-fix">
          <CardLayout layout="carousel">
            {featuredTripIdeas1?.map((idea) => (
              <TripIdeaCard
                key={idea.id}
                title={idea.title}
                heroImageUrl={idea.imageSrc}
                heroImageMeta=""
                numberOfDays={idea.numberOfDays}
                url={idea.href}
                pricePerPerson={{
                  price: idea.price,
                  currency: {
                    availableToCustomers: true,
                    symbol: idea.currency.symbol,
                    code: idea.currency.abbreviation,
                  } as site1Api.Currency,
                }}
                cardHref={idea.href}
                cardTarget={idea.target}
                customiseAndRequestHref={idea.threeStepRF}
                customiseAndRequestTarget={idea.customiseAndRequestTarget}
                customiseAndRequestLabel={t(
                  "web:CountryPage_TripIdeaCard_CustomiseAndRequestLabel",
                  "Customize and request"
                )}
              />
            ))}
          </CardLayout>
        </div>
      )}

      {data.featuredlocation2ImagepulloutCta && (
        <div className="container my-8">
          <ImagePullout
            heading={data.featuredlocation2ImagepulloutTitle}
            text={data.featuredlocation2ImagepulloutDescription}
            callToAction={data.featuredlocation2ImagepulloutCta as CallToAction}
            bgSrc={data.featuredlocation2ImagepulloutImage.sourceUrl}
          />
        </div>
      )}

      {featuredTripIdeas2?.length > 0 && (
        <div className="container my-8 overflow-iphone-fix">
          <CardLayout layout="carousel">
            {featuredTripIdeas2?.map((idea) => (
              <TripIdeaCard
                key={idea.id}
                title={idea.title}
                heroImageUrl={idea.imageSrc}
                heroImageMeta=""
                numberOfDays={idea.numberOfDays}
                url={idea.href}
                pricePerPerson={{
                  price: idea.price,
                  currency: {
                    availableToCustomers: true,
                    symbol: idea.currency.symbol,
                    code: idea.currency.abbreviation,
                  } as site1Api.Currency,
                }}
                cardHref={idea.href}
                cardTarget={idea.target}
                customiseAndRequestHref={idea.threeStepRF}
                customiseAndRequestTarget={idea.customiseAndRequestTarget}
                customiseAndRequestLabel={t(
                  "web:CountryPage_TripIdeaCard_CustomiseAndRequestLabel",
                  "Customize and request"
                )}
              />
            ))}
          </CardLayout>
        </div>
      )}
    </Page>
  );
}

export default PageWrapper;
